
import { Component, Vue } from "vue-property-decorator";

//本地引入
import DTCls from "@/utils/datetime";
import { lockList } from "@/api/request/user";
import Pagination from "@/comp/Pagination/index.vue";

//组件
@Component({
  name: "LockList",
  components: {
    Pagination,
  },
})

//组件函数
export default class extends Vue {
  //定义变量
  private list: any[] = [];
  private total: number = 0;
  private currentItem: any = {};
  private listLoading: boolean = true;
  private detailDialog: boolean = false;

  //类型数据
  private statusList = [
    { id: -1, name: "全部" },
    { id: 0, name: "冻结中" },
    { id: 1, name: "已解冻" },
  ];

  //请求数据
  private listQuery: any = {
    //页面数据
    page: 1,
    page_size: 20,

    //临时数据
    user_id_str: "",

    //筛选数据
    status: -1,
    user_id: 0,
  };

  //创建
  created() {
    //获取数据
    this.getList();
  }

  //获取数据
  private async getList() {
    //显示加载
    this.listLoading = true;

    //数据赋值
    this.listQuery.user_id = Number(this.listQuery.user_id_str);

    //获取记录
    const { data } = await lockList(this.listQuery);

    //数据赋值
    this.list = data.list;
    this.total = data.total;

    //隐藏加载
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //处理查询
  private handleFilter(): void {
    //数据赋值
    this.listQuery.page = 1;

    //获取数据
    this.getList();
  }
}
